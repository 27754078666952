import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { HasPermission } from "components/shared/app-permission";
import OrdersTab from "./orders";
import ContactsTab from "./contacts";
import ChangeLogsTab from "./change-logs";
import AccessLogsTab from "./access-logs";
import EquipmentTab from "./equipment";
import WalkInOrdersTab from "./walk_in_orders";
import LineItemEligiblity from "./line-item-eligiblity";
import HcpcHistory from "./hcpc-history";
import PatientCmns from "./cmns";
import PatientDocuments from "./patient-documents";
import PatientTasks from "./tasks";
import TrackingInfoTab from "./tracking-info";
import SpecialFieldsTab from "./special-fields";
import XZEROHistoryTab from "./xzero-history";
import ComplianceData from "./compliance-data/index";
import FormsData from "./forms/FormsData";
import PatientAppointments from "./appointments/index";
import { browserHistory } from "browser-history";

const Tabs = [
  { name: "Orders", permission: "orderSupplies" },
  { name: "Walk-in-Orders", permission: "orderSupplies" },
  { name: "Contacts" },
  { name: "Change Logs" },
  { name: "Access Logs" },
  { name: "Equipment" },
  { name: "Line Item Eligibility", permission: "orderSupplies" },
  { name: "HCPC History", permission: "new_hcpc_eligibility" },
  { name: "CMNs", permission: "orderSupplies" },
  { name: "Documents" },
  { name: "Tasks", permission: "patientTasks" },
  { name: "Tracking" },
  { name: "Special Fields" },
  { name: "Forms Data" },
  { name: "Compliance Data" },
  { name: "Appointments" }
];

function PatientTabs({
  patientId,
  readOnly,
  walkInOrders,
  xZeroRecords,
  patientInfo,
  defaultSelectedTab,
  displayComplianceDataSpinner,
  isViewingDashboard,
  setIsCompliant,
  reloadComplianceTab
}) {
  const [selectedTab, setSelectedTab] = useState("Orders");
  const [renderTabs] = useState(Tabs);

  React.useEffect(() => {
    selectDefaultTab();
  }, [defaultSelectedTab, renderTabs]);

  const selectDefaultTab = () => {
    if (
      defaultSelectedTab &&
      renderTabs.find(tab => tab.name === defaultSelectedTab)
    ) {
      setSelectedTab(defaultSelectedTab);
    }
  };

  React.useEffect(() => {
    if(selectedTab == "Appointments")
      browserHistory.replace(
        `${browserHistory.location.pathname}?selectedTab=Appointments`
      );
      else
        browserHistory.replace(`${browserHistory.location.pathname}`);

  }, [selectedTab])

  return (
    <div className="patient-tabs">
      <ul>
        {renderTabs.map(({ name, permission }) =>
          permission ? (
            <HasPermission
              permission={permission}
              allowAdmins={false}
              key={name}
            >
              <li
                className={selectedTab == name ? "active" : ""}
                onClick={() => setSelectedTab(name)}
              >
                {name}
              </li>
            </HasPermission>
          ) : (
            <li
              className={selectedTab === name ? "active" : ""}
              onClick={() => setSelectedTab(name)}
              key={name}
            >
              {name}
            </li>
          )
        )}
      </ul>
      <div className="content">
        {selectedTab === "Orders" && <OrdersTab patientId={patientId} />}
        {selectedTab === "Contacts" && <ContactsTab patientId={patientId} />}
        {selectedTab === "Change Logs" && (
          <ChangeLogsTab patientId={patientId} patientInfo={patientInfo} />
        )}
        {selectedTab === "Access Logs" && (
          <AccessLogsTab patientId={patientId} />
        )}
        {selectedTab === "Equipment" && (
          <EquipmentTab patientId={patientId} readOnly={readOnly} />
        )}
        {selectedTab === "Walk-in-Orders" && (
          <div>
            <WalkInOrdersTab walkInOrders={walkInOrders} />
          </div>
        )}
        {selectedTab === "Line Item Eligibility" && (
          <div>
            <div>
              <LineItemEligiblity patientId={patientId} />
            </div>
            <div>
              <XZEROHistoryTab xZeroRecords={xZeroRecords} />
            </div>
          </div>
        )}
        {selectedTab === "HCPC History" && (
          <div>
            <HcpcHistory patientId={patientId} />
          </div>
        )}
        {selectedTab === "CMNs" && (
          <PatientCmns patientId={patientId} readOnly={readOnly} />
        )}
        {selectedTab === "Documents" && (
          <div>
            <PatientDocuments patientId={patientId} readOnly={readOnly} />
          </div>
        )}
        {selectedTab === "Tasks" && (
          <PatientTasks
            patientId={patientId}
            readOnly={readOnly}
            patientInfo={patientInfo}
          />
        )}
        {selectedTab === "Tracking" && (
          <TrackingInfoTab patientId={patientId} readOnly={readOnly} />
        )}
        {selectedTab === "Special Fields" && (
          <SpecialFieldsTab patientId={patientId} readOnly={readOnly} />
        )}
        {selectedTab === "Compliance Data" && (
          <ComplianceData
            patientId={patientId}
            patientInfo={patientInfo}
            complianceActive={patientInfo?.patientTypeData?.compliance_status}
            displaySpinner={displayComplianceDataSpinner}
            setIsCompliantForBanner={value => setIsCompliant(value)}
            companyManagesCompliance={patientInfo?.CompanyManagesCompliance}
            CompanyManagesResupply={patientInfo?.CompanyManagesResupply}
            reloadComplianceTab={reloadComplianceTab}
          />
        )}
        {selectedTab === "Forms Data" && (
          <FormsData
            patientId={patientId}
            patientTypeData={patientInfo.patientTypeData}
            isInsideModal={isViewingDashboard}
            patientInfo={patientInfo}
          />
        )}
        {selectedTab === "Appointments" && (
          <PatientAppointments patientId={patientId} />
        )}
      </div>
    </div>
  );
}

export default connect((state, { patientId }) => ({
  walkInOrders: selectors.getPatientWalkinOrders(state, patientId),
  xZeroRecords: selectors.getPatientsXZEROHistory(state, patientId)
}))(PatientTabs);

PatientTabs.propTypes = {
  patientId: PropTypes.string.isRequired,
  walkInOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  xZeroRecords: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
  patientInfo: PropTypes.object,
  defaultSelectedTab: PropTypes.string,
  displayComplianceDataSpinner: PropTypes.bool,
  isViewingDashboard: PropTypes.func,
  setIsCompliant: PropTypes.func,
  reloadComplianceTab: PropTypes.func
};

PatientTabs.defaultProps = {
  readOnly: false
};
